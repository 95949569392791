<template>
  <Dialog :commonDialog="dialog">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <v-form
        ref="product_import"
        v-model="valid_import"
        lazy-validation
        v-on:submit.stop.prevent="import_rows()"
      >
        <v-container fluid class="pt-0 my-4">
          <v-btn
            v-on:click="download_sample()"
            :disabled="loading_imported"
            class="mx-2 custom-grey-border white--text custom-bold-button"
            color="blue darken-4"
          >
            Download Sample
          </v-btn>
          <v-file-input
            :disabled="loading_imported"
            :rules="[
              (value) =>
                !value ||
                value.size < 5000000 ||
                'File size should be less than 5 MB!',
            ]"
            v-on:change="handle_file_upload"
            placeholder="Select Excel File"
            color="blue darken-4"
            show-size
            class="my-3"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ></v-file-input>

          <div class="import-success-message font-weight-600 font-size-18 red--text text--lighten-1 w-100">
            <ul>
              <li v-for="(message, index) in data_excel" :key="index">
                {{ message }}
              </li>
            </ul>
          </div>
          <div class="import-success-message font-weight-600 font-size-18 green--text text--lighten-1 w-100">
            <ul>
              <li v-for="(message, index) in data_upload" :key="index">
                {{ message }}
              </li>
            </ul>
          </div>

          <table
            class="mt-5 font-weight-600 font-size-18 red--text text--lighten-1 w-100"
          >
            <tr>
              <td valign="top" align="right">Note:</td>
              <td valign="top" align="left">
                <em
                  >The first line in downloaded sample xlsx file should remain
                  as it is.<br />Please do not change the order of columns.</em
                >
              </td>
            </tr>
          
          </table>
          <!-- <tr v-for="(item, index) in  data_excel"  :key="index" >
              <td valign="top" >
                {{ item }}
              </td>
            </tr> -->
        </v-container>
      </v-form>
    </template>
    
    <template v-slot:action>
      <v-btn
        v-if="!excel_imported"
        :disabled="!valid_import || loading_imported"
        class="mx-2 custom-grey-border white--text custom-bold-button"
        color="blue darken-4"
        v-on:click="import_rows()"
      >
        Import
      </v-btn>
      <v-btn
        :disabled="loading_imported"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/view/components/Import-Dialog.vue";
// import JwtService from "@/core/services/jwt.service";
import { IMPORTPRODUCT } from "@/core/lib/product.lib";
// import { SET_MESSAGE } from "@/core/services/store/common.module";
  import { SET_ERROR } from "@/core/services/store/common.module";
// import { POST } from "@/core/services/store/request.module";
// import { ErrorEventBus, ClearEventBus } from "@/core/lib/message.lib";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: null,
    },
    // sampleUrl: {
    //   type: String,
    //   required: true,
    //   default: null,
    // },
    apiUrl: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      valid_import: true,
      data_excel:[],
      data_upload:[],
      excel_imported: false,
      loading_imported: false,
      success_message: [],
      error_message: [],
    };
  },
  methods: {
    handle_file_upload(param) {
      this.file = param;
      this.data_excel=null;
      this.data_upload=null;
      this.excel_imported = false;
      this.success_message = [];
      this.error_message = [];
    },
    download_sample() {
      // const token = JwtService.getToken();
      const url = "/sample-imports/products12.xlsx";
      window.open(url, "_target");
    },
  //   import_rows() {
  //     const _this = this;

  //     const file = _this.file;

  //     ClearEventBus.$emit("clear:success", true);

  //     ClearEventBus.$emit("clear:error", true);

  //     if (!file) {
  //       ErrorEventBus.$emit("update:error", "Please selects a valid file.");
  //       return false;
  //     }

  //     _this.loading_imported = true;
  //     _this.excel_imported = false;
  //     _this.success_message = [];
  //     _this.error_message = [];

  //     const data = new FormData();

  //     data.append("excel_file", file);

  //     _this.$store
  //       .dispatch(POST, { url: this.apiUrl, data })
  //       .then(({ data }) => {
  //         _this.success_message = data.success || [];
  //         _this.error_message = data.error || [];
  //         _this.excel_imported = true;
  //       })
  //       .catch((error) => {
  //         _this.logError(error);
  //         alert(1)

  //       })
  //       .finally(() => {
  //         _this.loading_imported = false;

  //       });
  //   },
  // },

  async import_rows() {
      const _this = this;

   try {
        _this.pageLoading = true;
        const file = _this.file;  
        _this.loading_imported = true;
        _this.excel_imported = false;
        _this.success_message = [];
        _this.error_message = [];
         console.log(file, "excel1"); 
         const formata = new FormData();

         formata.append("excel_file", file);
       
     const data = await IMPORTPRODUCT(formata);
     this.data_excel = data.message;
     this.data_upload = data.upload;
console.log(this.data_excel,'ffsdf')
_this.loading_imported=false;

     
    //  _this.$store.commit(SET_MESSAGE, [
    //    { model: true, message: "" },
    //  ]);
   
 } catch (error) {
   _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
   _this.loading_imported=false;
 } finally {
   _this.pageLoading = false;
 }

},
  },
  components: {
    Dialog,
  },
};
</script>
