<template>
    <v-row justify="center">
      <v-dialog
        origin="top center"
        v-model="commonDialog"
        persistent
        scrollable
        :fullscreen="fullscreen"
        :hide-overlay="hideOverlay"
        :transition="transition"
        content-class="common-dialog"
        :max-width="dialogWidth"
      >
        <v-card>
          <v-card-title
            v-if="$slots.title"
            class="headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"
          >
            <slot name="title"></slot>
          </v-card-title>
          <v-card-text v-if="$slots.body" class="p-8 font-size-16">
            <slot name="body"></slot>
          </v-card-text>
          <v-divider class="my-0"></v-divider>
          <v-card-actions v-if="$slots.action">
            <v-spacer></v-spacer>
            <slot name="action"></slot>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  <style scoped>
  .v-dialog__content {
    align-items: start;
  }
  </style>
  
  <script>
  export default {
    props: {
      commonDialog: {
        type: Boolean,
        required: true,
        default: false,
      },
      dialogWidth: {
        type: Number,
        default: 768,
      },
      fullscreen: {
        type: Boolean,
        default: false,
      },
      hideOverlay: {
        type: Boolean,
        default: false,
      },
      transition: {
        type: String,
        default: null,
      },
    },
  };
  </script>
  