var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"custom-page-header-count pt-0 px-0"},[_c('v-layout',[_c('h1',{staticClass:"text-h5 custom-header-blue-text"},[_c('v-select',{staticClass:"pt-0 mt-0 listing-status main-listing-status-filter",attrs:{"items":_vm.statusList,"hide-details":"","item-color":"cyan","item-text":"text","item-value":"dbvalue","menu-props":{
        contentClass: 'test-list'
      }},on:{"change":_vm.setStatus},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1),_c('v-list-item-action',[_c('v-avatar',{staticClass:"white--text",attrs:{"size":30,"color":item.color,"text-color":item.textcolor}},[(item && item.dbvalue && item.dbvalue == 1)?[_vm._v(_vm._s(item.total))]:[_vm._v(" "+_vm._s(item.product_status_count))]],2)],1)]}}]),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),(false)?[_vm._l((_vm.dataLoadingList),function(data,index){return [_c('v-flex',{key:index,staticClass:"py-0",class:{
            'mr-1': index == 0,
            'ml-1': !_vm.dataLoadingList[index + 1],
            'mx-1': index != 0 && _vm.dataLoadingList[index + 1],
          }},[_c('div',{class:'card m-0 top_card card-custom gutter-b border-' + data.headercolor},[_c('div',{staticClass:"card-body p-0"},[_c('div',{class:'card-rounded-bottom mid_part '},[_c('div',{staticClass:"d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"},[_c('span',{staticClass:"symbol symbol-circle symbol-50 symbol-light-danger mr-2"},[_c('span',{staticClass:"symbol-label"},[_c('span',{class:'svg-icon svg-icon-xl svg-icon-' + data.headercolor},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/' + _vm.moduleType + '.svg')}})],1)])]),_c('div',{staticClass:"d-flex flex-column text-right"},[_c('h5',{staticClass:"text-dark-75 font-weight-bold mt-2 min-width-150px"},[_c('v-skeleton-loader',{staticClass:"custom-skeleton",attrs:{"type":"text"}})],1),_c('span',{class:'qnt_val font-weight-bolder text-' + data.headercolor},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":data.headercolor}})],1)])])])])])])]})]:_vm._e(),(false)?[_vm._l((_vm.statusList),function(data,index){return [(data.text)?_c('v-flex',{key:index,staticClass:"py-0",class:{
            'mr-1': index == 0,
            'ml-1': !_vm.statusList[index + 1],
            'mx-1': index != 0 && _vm.statusList[index + 1],
          }},[_c('div',{class:'card m-0 top_card card-custom gutter-b border-' + data.headercolor},[_c('div',{staticClass:"card-body p-0"},[_c('div',{class:'card-rounded-bottom mid_part shadow-sm bg-light-' +
                  data.color +
                  ' bg-front-' +
                  data.textcolor},[_c('div',{staticClass:"d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"},[_c('span',{staticClass:"symbol symbol-circle symbol-50 symbol-light-danger mr-2"},[_c('span',{staticClass:"symbol-label"},[_c('span',{class:'svg-icon svg-icon-xl svg-icon-' + data.color},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/' + _vm.moduleType + '.svg')}})],1)])]),_c('div',{staticClass:"d-flex flex-column text-right bTitle"},[_c('a',{attrs:{"href":_vm.href},on:{"click":_vm.navigate}},[_c('h5',{staticClass:"text-dark-75 font-weight-bold mt-2"},[_vm._v(" "+_vm._s(data.text)+" ")]),(data.value == 'all')?[_c('span',{class:'qnt_val font-weight-bolder text-' + data.color},[_vm._v(_vm._s(data[_vm.allkey]))])]:[_c('span',{class:'qnt_val font-weight-bolder text-' + data.color},[_vm._v(_vm._s(data[_vm.countkey]))])]],2)])])])])])]):_vm._e()]}),_vm._l((_vm.extraList),function(data,index){return [_c('v-flex',{key:`extra-${index}`,staticClass:"py-0",class:{
            'mr-1': index == 0,
            'ml-1': !_vm.extraList[index + 1],
            'mx-1': index != 0 && _vm.extraList[index + 1],
          }},[_c('div',{class:'card m-0 top_card card-custom gutter-b border-primary'},[_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"card-rounded-bottom mid_part"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"},[_c('span',{staticClass:"symbol symbol-circle symbol-50 symbol-light-danger mr-2"},[_c('span',{staticClass:"symbol-label"},[_c('span',{class:'svg-icon svg-icon-xl svg-icon-primary'},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/' + _vm.moduleType + '.svg')}})],1)])]),_c('div',{staticClass:"d-flex flex-column text-right bTitle"},[_c('router-link',[_c('a',{attrs:{"href":_vm.href},on:{"click":_vm.navigate}},[_c('h5',{staticClass:"text-dark-75 font-weight-bold mt-2"},[_vm._v(" "+_vm._s(data.title)+" ")]),_c('span',{class:'qnt_val font-weight-bolder text-primary'},[_vm._v(_vm._s(data.value))])])])],1)])])])])])]})]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }